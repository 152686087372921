import { LatLng, LatLngBounds } from 'leaflet';
import { DatesFromTo, ProductName, Provider, ReportInterval } from './model-api';

export enum ListingType {
    IMAGE = 'IMAGE',
    TILE_LAYER = 'TILE_LAYER',
    WMS = 'WMS',
    WMTS = 'WMTS',
    COG = 'COG',
    EXTERNAL_TILE_LAYER = 'EXTERNAL_TILE_LAYER',
}

export enum ListingFileType {
    LEGEND = 'LEGEND',
    OTHER = 'OTHER',
    DOC = 'DOC',
    UNKNOWN = 'UNKNOWN',
}

// The only one we care about for reported maps
export enum ContactSubjectType {
    PROBLEM_WITH_LISTING = 'Problem with listing',
}

export enum Permission {
    Read,
    Write,
    Review,
    Admin,
    Owner,
}

export enum Review {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
}

export enum UserRole {
    ALL_USERS = 'ALL_USERS',
    ORDERS_ADMIN = 'ORDERS_ADMIN',
    ORDERS_SUPPLIER = 'ORDERS_SUPPLIER',
    TILE_ADMIN = 'TILE_ADMIN',
    REPORTS = 'REPORTS',
    REPORTS_DRAW_PROJECT = 'REPORTS_DRAW_PROJECT',
    VOUCHERS = 'VOUCHERS',
    CONTACT_US = 'CONTACT_US',
    SUPER_MAP = 'SUPER_MAP',
    ANALYTICS = 'ANALYTICS',
    DMT_ADMIN = 'DMT_ADMIN',
    SUBDOMAINS = 'SUBDOMAINS',
    COMMENTS = 'COMMENTS',
    USERS_ADMIN = 'USERS_ADMIN',
    TRACKERS = 'TRACKERS',
    TMS = 'TMS',
    WMS = 'WMS',
    BILLING = 'BILLING',
    STAC = 'STAC',
}

export enum ReviewRejectionCategory {
    WRONG_LOCATION = 'WRONG_LOCATION',
    POTENTIAL_COPYRIGHT_ISSUE = 'POTENTIAL_COPYRIGHT_ISSUE',
    NEEDS_BETTER_DESCRIPTION = 'NEEDS_BETTER_DESCRIPTION',
    POOR_IMAGE_QUALITY = 'POOR_IMAGE_QUALITY',
    NOT_MAP_IMAGE = 'NOT_MAP_IMAGE',
    UPLOAD_ERROR = 'UPLOAD_ERROR',
    INCORRECT_VIEWABLE_FORMAT = 'INCORRECT_VIEWABLE_FORMAT',
    OTHER = 'OTHER',
}

export interface ListingFileDTO {
    id: number;
    listingId: number;
    type: ListingFileType;
    fileName: string;
    fileSize: number;
    created: string;
    title?: string;
    description?: string;
    url?: string;
}

export interface ListingDTO {
    id: number;
    listingType: ListingType;
    geometryWKT: string;
    bboxWKT: string;
    totalLikes: number;
    totalViews: number;
    totalComments: number;
    featured: string[];
    filename: string;
    metadata: string;
    previewUrl: string;
    priceUsd: number;
    minZoom?: number;
    filehash: string;
    userId: string;
    userName?: string;
    avatarUrl?: string;
    reputable: boolean;
    createdAt: number;
    updatedAt: number;
    title: string;
    seoTitle: string;
    description: string;
    tags: string[];
    categories?: string[];
    license: string;

    files: ListingFileDTO[];

    review: string;
    reviewedById?: string;
    reviewedByEmail?: string;
    reviewedAt?: number;

    rejectionReasons?: ReviewRejectionReason[];

    filesize: number;
    error: string;
    feedback: string;
    userEmail: string;
    processing: boolean;
    gdalInfo: string;
    exif: string;

    largePreviewUrl?: string;
    tileUrl: string;
    fullResUrl: string;
    domainName: string;

    distoredGeometry?: string;

    layerName?: string;
    layerTitle?: string;
    layerAdminNotes?: string;
    layerWmsServerId?: string;
    serverUrl?: string;
    skipWmsProxy?: boolean;
    layerSrs?: string;
    layerImageFormat?: string;
    layerSpeedKbps?: number;
    layerVersion?: string;
    tms?: boolean;
    maxZoom?: number;
    tileMatrixSet?: ListingTileMatrixSet;
    style?: string;
    geometryGeoJson?: string;
}

export interface ListingTileMatrixSet {
    crs: string;
    identifier: string;
    matrices: ListingTileMatrix[];
}

export interface ListingTileMatrix {
    identifier: string;
    matrixHeight: number;
    matrixWidth: number;
    tileHeight: number;
    tileWidth: number;
    topLeftPoint: string;
}

export interface ListingForOwnerOrAdminDTO extends ListingDTO {
    sales?: number;
}

export interface ProjectPermissionDTO {
    id: number;
    name: string;
    permission: Permission;
    ftpUserName: string;
}

export interface DomainPermissionDTO {
    domain: string;
    permission: Permission;
}

export interface BillingInfoDTO {
    firstName?: string;
    lastName?: string;
    company?: string;
    address?: string;
    country?: string;
    city?: string;
    postCode?: string;
    phone?: string;
    paypal?: string;
}

export interface UserDTO {
    userId?: string;
    email?: string;
    ethAddress?: string;
    bio?: string;
    name?: string;
    avatarUrl?: string;
    twitterUrl?: string;
    ftpUsername?: string;
    facebookUrl?: string;
    instagramUrl?: string;
    websiteUrl?: string;
    billingInfo?: BillingInfoDTO;
    projects?: ProjectPermissionDTO[];
    domains?: DomainPermissionDTO[];
    createdAt?: number;
    roles?: UserRole[];
    reputable?: boolean;
    communityModerator?: boolean;
    longDescription?: string;
}

export interface UploadCredentialsDTO {
    stsCredentials: {
        securityToken: string;
        accessKeyId: string;
        accessSecretKey: string;
    };
    ossRegion: string;
    bucketName: string;
    filename?: string;
    path?: string;
    listingId?: number;
}

export interface DomainUserDTO {
    email: string;
    permissions: Permission;
    domainName?: string;
    name?: string;
    userId?: string;
    invitationId?: string;
}

export interface ResultDTO {
    success: boolean;
}

export interface SponsorListingsDTO {
    listings: ListingDTO[];
    total: number;
}

export interface ReviewItemDTO {
    id: number;
    review: string;
    rejectionReasons?: ReviewRejectionReason[];
    priority?: number;
    featured?: string;
    category?: string;
    feedback?: string;
    error?: string;
}

export interface ReviewRejectionReason {
    category: ReviewRejectionCategory;
    reason: string;
    feedback: string;
    blogLink?: string;
}

export interface ReviewsDTO {
    reviews: ReviewItemDTO[];
}

export interface DownloadLinkDTO {
    presignedUrl: string;
    filename: string;
}

export interface Voucher {
    type: string;
    code: string;
    amount?: number;
    percentage?: number;
    validFrom?: number;
    validTo?: number;
    usageLimit?: number;
    userId?: string;
    userMultiple: boolean;
    watermarkRequired?: boolean;
    maxArea?: number;
    maxImages?: number;
    durationInMonth?: number;
    freeTrialInDays?: number;
}

export interface VoucherDTO extends Voucher {
    createdAt: number;
    createdBy: string;
    tasksId: number[];
}

export interface DeleteListingsRes {
    deleted: { filehash: string; transactionHash: string };
}

export interface PurchaseItem {
    id: string;
    amount: number;
    paidAt: number;
    ownerId: string;
    createdAt: number;
    purchaseId: string;
    type: string;
    typeData: string;
    currency: string;
    paidOutAt?: number;
    paidOutById: string;
    // TODO object has changed over time and looks like payouts now exist under data DP 12/9/2022
    // Does not seem to be backward compatible as results where not displaying on the client
    data?: any;
}

export interface Project {
    id: number;
    name: string;
    description: string;
}

export interface Metadata {
    title?: string;
    description?: string;
    tags?: string[];
    streetAddress?: string;
    make: string;
    model: string;
    focalLength?: string;
    apertureValue?: string;
    fNumber?: string;
    shutterSpeed?: string;
    lightSource?: string;
    dateTaken?: string;
    dateUploaded?: string;
    altitude?: string;
}

export interface BaseReportDTO {
    createdAt: number;
    maps: number;
    mapsArea: number;
    mapsSize: number;
    mapsRejected: number;
    mapsAreaRejected: number;
    mapsSizeRejected: number;
}

export interface DomainReportDTO extends BaseReportDTO {
    name: string;
    owner: string;
    lastActivity: number;
    tileConsumption: number;
    tileStatus: string;
    domainId: number;
}

export interface UserReportDTO extends BaseReportDTO {
    id: string;
    email: string;
    ethAddress: string;
    name?: string;
    created: Date;
    createdAt: number;
}

export interface MainReportDTO {
    users: number;
    subdomains: number;
    soar: DomainReportDTO;
    others: DomainReportDTO;
}

export interface SubdomainsReportDTO {
    domains: DomainReportDTO[];
}

export interface ReportIntervalDTO {
    label: string;
    total: number;
}

export interface ReportDTO {
    total: number;
    intervals: ReportIntervalDTO[];
}

export interface SoarReportDTO {
    soar: DomainReportDTO;
    usersByDay: ReportDTO;
    usersByMonth: ReportDTO;
}

export interface OverviewDTO {
    from: number;
    to: number;
    users: number;
    approvedImages: number;
    rejectedImages: number;
    approvedMaps: number;
    rejectedMaps: number;
}

export interface UsersReportDTO {
    list: UserReportDTO[];
    total: number;
}

export interface ContactUsDTO {
    id: string;
    name: string;
    email: string;
    industry: string;
    countryCode: string;
    subject: string;
    message: string;
    createdAt: number;
    listingId: number;
    handledAt: number;
    handledBy: string;
    handledByEmail: string;
    geometryWKT: string;
    base64Kml: string;
    ipAddressCountry: string;
    ipAddressCity: string;
}

export interface ReportListingsIntervalDTO {
    label: string;
    mapApproved: number;
    mapRejected: number;
    mapPending: number;
    imageApproved: number;
    imageRejected: number;
    imagePending: number;
}

export interface ReportListingsDTO {
    total: number;
    intervals: ReportListingsIntervalDTO[];
}

export interface CFMonthlyStatDTO {
    requests: number;
    bytes: number;
    threats: number;
    label?: string;
}

export interface DailyReportCFFilter {
    dates: DatesFromTo;
}

export interface MonthlyReportCFDTO {
    dateLabel: string;
    uniques: number;
    countries: CFMonthlyStatDTO[];
    sum: CFMonthlyStatDTO;
}

export interface CFDailyStatDTO {
    requests: number;
    bytes: number;
    visits: number;
    label?: string;
}

export interface ReportUploadersDTO {
    intervals: [
        {
            label: string;
            map: number;
            mapNew: number;
            mapApproved: number;
            mapRejected: number;
            mapApprovedNew: number;
            mapRejectedNew: number;
            image: number;
            imageNew: number;
            imageApproved: number;
            imageRejected: number;
            imageApprovedNew: number;
            imageRejectedNew: number;
            total: number;
        }
    ];
}

export interface DailyReportCFDTO {
    dateLabel: string;
    total: CFDailyStatDTO[];
    countries: CFDailyStatDTO[];
    browsers: CFDailyStatDTO[];
    deviceTypes: CFDailyStatDTO[];
    hosts: CFDailyStatDTO[];
    oss: CFDailyStatDTO[];
    referers: CFDailyStatDTO[];
}

export interface ListingCommentsDTO {
    listingId: number;
    listingTitle: string;
    totalComments: number;
}

export interface AnalyticsItemDTO {
    created?: Date;
    createdAt: number;
    geometryWKT?: string;
    email?: string;
    ipAddress?: string;
    url?: string;
    sensor?: string;
    category?: string;
    layers?: string;
    satellite?: string;
    provider?: string;
    action?: string;
    id: number;
    countryCode?: string;
    product?: string;
    bbox?: string;
    subdomain?: string;
    crs?: string;
    userId?: string;
    referer: string;
    ipLocationWKT: string;
    pointWKT?: string;
    layerSrs?: string;
}

export interface AnalyticsDTO {
    list: AnalyticsItemDTO[];
    total: number;
}

export interface AnalyticsFilter {
    dates?: DatesFromTo;
    satellite?: ProductName;
    provider?: Provider;
    ipAddress?: string;
    email?: string;
    limit?: number;
    offset?: number;
    bbox?: LatLngBounds;
}

export interface HeatmapResult {
    date: Date;
    latlng: LatLng;
}

export interface AnalyticsReportProductDTO {
    name: string;
    providerName: string;
    actions: Map<string, number>;
}

export interface AnalyticsReportCountryDTO {
    countryCode: string;
    products: AnalyticsReportProductDTO[];
}

export interface AnalyticsReportDTO {
    dateLabel: string;
    products: AnalyticsReportProductDTO[];
    countries: AnalyticsReportCountryDTO[];
    listingsView: Map<number, number>;
}

export interface CFMonthlyStatDTO {
    requests: number;
    bytes: number;
    threats: number;
    label?: string;
}

export interface DMTMetadata {
    area: number;
    totalImages: number;
    coverageLayersWKT?: string[];
    coverageBoundingWKT?: string;
}

export interface DmtTaskDTO {
    id: number;
    company: string;
    country?: string;
    countryCode?: string;
    industry: string;
    userEmail: string;
    userId: string;
    totalPrice?: number;
    totalTax?: number;
    voucher?: string;
    voucherValueUsed?: number;
    currency?: string;
    receiptUrl?: string;
    xeroInvoiceUrl?: string;
    status?: string;
    geometryWKT?: string;
    tileUrlOrtho?: string;
    tileUrlDem?: string;
    previewUrlOrtho?: string;
    previewUrlDem?: string;
    orderNumber?: string;

    created: number;
    submittedAt?: number;
    deliveredAt?: number;
    domain?: string;
    metadata?: DMTMetadata;
    errorCode?: string;
}

export interface XmpData {
    [key: string]: string;
}

export interface CommentDTO {
    id: string;
    userId: string;
    userName: string;
    userEmail: string;
    userAvatar: string;
    updatedAt: number;
    createdAt: number;
    listingId?: number;
    listingTitle?: string;
    text: string;
    annotations: string;
    vote?: number;
}

export interface ReportedCommentDTO {
    id: string;
    userId: string;
    userName: string;
    userEmail: string;
    reason: string;
    message: string;
    createdAt: number;
    review: Review;
    comment: CommentDTO;
}

export interface UserForAdminDTO {
    id: string;
    name: string;
    email: string;
    ethAddress: string;
    createdAt: number;
    updatedAt: number;
    reputable: boolean;
    communityModerator: boolean;
}

export interface FileInfoDTO {
    ossKey: string;
    size: number;
    created: string;
}

export interface HashTagsDTO {
    tag: string;
    count: number;
}

export interface MapViewsDTO {
    id: number;
    total: number;
    product: string;
}

export interface AnalyticsMapViewDTO {
    total: number;
    totalImages: number;
    totalMaps: number;
    totalEmbd: number;
    totalMapDraw: number;
    totalMapCanva: number;
    totalWms: number;
    totalWmts: number;
    totalCog: number;
    totalExtTileLayer: number;
    totalOrders: number;
    views: MapViewsDTO[];
}

export type ReportReview = 'NOTIFIED' | 'DELETED' | 'IGNORED' | 'PENDING';

export interface ReportedListingDTO {
    id: number;
    listingId: number;
    domainId: number;
    reportedBy: string;
    reportedByEmail: string;
    listingTitle: string;
    reason: string;
    review: ReportReview;
    countryCode: string;
    reviewedBy?: string;
    reviewedByEmail?: string;
    reviewedAt?: string;
    reportedAt: string;
}

export interface DrawProjectReportDTO {
    id: number;
    title: string;
    createdAt: string;
    dataByteSize: number;
    email: string;
    featureCount: number;
    mapCount: number;
    mapIds: number[];
    public: boolean;
    viewPortsWKT: string[];
    deletedAt: string;
    pageCount: number;
}
